// Custom Option component
export default function ReactSelectUserOption({ data, innerRef, innerProps }) {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ display: "flex", alignItems: "center", padding: "10px" }}
    >
      <img
        src={data.avatar_url}
        className="avatar rounded"
        style={{
          width: "40px",
          height: "40px",
          marginRight: "10px",
        }}
      />
      <div>
        <div style={{ fontWeight: "bold" }}>
          {data.name} (ID: {data.id})
        </div>
        <div style={{ fontSize: "12px", color: "gray" }}>{data.caption}</div>
      </div>
    </div>
  );
}
