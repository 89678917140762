export default function LazyImage({ src, className, placeholder, style }) {
  return (
    <img
      src={src ?? placeholder}
      className={className}
      loading="lazy"
      style={style}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        if (placeholder) {
          currentTarget.src = placeholder;
        }
      }}
    />
  );
}
