import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { EnterpriseContext } from "./EnterpriseContext";
import { toast } from "react-toastify";
import LoadingButton from "../ui/LoadingButton";
import Separator from "../ui/Separator";

export default function ExportMemberProperties({
  container,
  user,
  toggleChangeRoleModal,
  reload,
}) {
  const es = new EnterpriseService();
  const { globalContext } = useContext(EnterpriseContext);

  const member = globalContext.member;
  const company = globalContext.company;

  const [localUser, setLocalUser] = useState(user);

  const [newRole, setNewRole] = useState(null);
  const [tags, setTags] = useState([]);

  const [roles, setRoles] = useState([]);

  const [ranks, setRanks] = useState([]);

  useEffect(() => {
    getTags();
    getRoles();
    getRanks();
  }, []);

  const getTags = async () => {
    if (es.can(member, "members.manage_roles")) {
      const result = await es.getCompanyTags(company.id);

      if (!result.message) setTags(result);
    }
  };

  const getRoles = async () => {
    if (es.can(member, "members.manage_roles")) {
      const result = await es.getCompanyRoles(company);
      if (!result.message) setRoles(result);
    }
  };

  const getRanks = async () => {
    if (es.can(member, "members.manage_roles")) {
      const result = await es.getCompanyRanks();

      if (!result.message) setRanks(result);
    }
  };

  const associateTags = async () => {
    const result = await es.associateCompanyTagsToUser(
      company.id,
      localUser.id,
      localUser.company_tags
    );
    if (result.success) {
      toast.success(container.localeManager.strings.dataUpdated);
      reload();
    } else {
      toast.error(result.message);
    }
  };

  const addTag = (id) => {
    if (id) {
      if (!localUser.company_tags.find((m) => m.id == id)) {
        const newTags = [...localUser.company_tags];
        newTags.push(tags.find((m) => m.id == id));
        setLocalUser({ ...localUser, company_tags: newTags });
      }
    }
  };

  const saveUser = async () => {
    const result = await es.changeRole(
      company.id,
      localUser.id,
      localUser.role_id
    );
    if (result.success) {
      toast.success(container.localeManager.strings.dataUpdated);
      reload();
    } else {
      toast.error(result.message);
    }
  };

  const saveRank = async () => {
    const result = await es.changeRank(
      company.id,
      localUser.id,
      localUser.company_rank_id
    );
    if (result.success) {
      toast.success(container.localeManager.strings.dataUpdated);
      reload();
    } else {
      toast.error(result.message);
    }
  };

  return (
    <Modal
      size="lg"
      className="big-modal"
      wrapClassName="big-modal-wrap"
      modalClassName="big-modal-container"
      contentClassName="big-modal-content"
      isOpen={true}
      toggle={() => toggleChangeRoleModal(false)}
    >
      <ModalHeader
        toggle={() => toggleChangeRoleModal(false)}
        close={
          <Button
            color="active-light-primary"
            className="btn-icon btn-sm w-30px h-30px ms-auto"
            onClick={() => toggleChangeRoleModal(false)}
          >
            <FontAwesomeIcon icon="times"></FontAwesomeIcon>
          </Button>
        }
      >
        Member Properties
      </ModalHeader>
      <ModalBody>
        {(localUser.role == null ||
          (localUser.role != null && !localUser.role.owner)) && (
          <>
            <FormGroup>
              <Label>{container.localeManager.strings.role}</Label>
              <Input
                value={localUser.role_id}
                onChange={(e) =>
                  setLocalUser({ ...localUser, role_id: e.target.value })
                }
                type="select"
              >
                <option value="">
                  {container.localeManager.strings.noRoleAssigned}
                </option>
                {roles
                  .filter((r) => !r.owner)
                  .map((r) => {
                    return <option value={r.id}>{r.name}</option>;
                  })}
              </Input>
            </FormGroup>
            <div className="mt-3">
              <LoadingButton size="sm" color="primary" onClick={saveUser}>
                {container.localeManager.strings.save}
              </LoadingButton>
            </div>
          </>
        )}
        {tags.length > 0 && (
          <>
            <Separator></Separator>
            <FormGroup>
              <Label>{container.localeManager.strings.tags}</Label>
              <Input type="select" onChange={(e) => addTag(e.target.value)}>
                <option></option>
                {tags.map((m) => {
                  return <option value={m.id}>{m.name}</option>;
                })}
              </Input>
              <div className="mt-3">
                {localUser.company_tags.map((m) => {
                  return (
                    <a
                      onClick={() => {
                        setLocalUser({
                          ...localUser,
                          company_tags: localUser.company_tags.filter(
                            (x) => x.id != m.id
                          ),
                        });
                      }}
                      className="badge badge-secondary me-3 mb-3"
                    >
                      {m.name}{" "}
                      <FontAwesomeIcon
                        icon="times"
                        className="me-0 ms-3"
                      ></FontAwesomeIcon>
                    </a>
                  );
                })}
              </div>
            </FormGroup>
            <div className="mt-3">
              <LoadingButton color="primary" size="sm" onClick={associateTags}>
                {container.localeManager.strings.save}
              </LoadingButton>
            </div>
          </>
        )}
        {ranks.length > 0 && (
          <>
            <Separator></Separator>
            <FormGroup>
              <Label>{container.localeManager.strings.rank}</Label>
              <Input
                value={localUser.company_rank_id}
                onChange={(e) =>
                  setLocalUser({
                    ...localUser,
                    company_rank_id: e.target.value,
                  })
                }
                type="select"
              >
                <option value="">
                  {container.localeManager.strings.noRankAssigned}
                </option>
                {ranks.map((r) => {
                  return <option value={r.id}>{r.name}</option>;
                })}
              </Input>
            </FormGroup>
            <div className="mt-3">
              <LoadingButton size="sm" color="primary" onClick={saveRank}>
                {container.localeManager.strings.save}
              </LoadingButton>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}
