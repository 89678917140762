import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function StarRating({ rating, addRatingAside }) {
  const getStars = () => {
    let color = "";

    if (rating >= 4) color = "text-success";

    if (rating < 4) color = "text-warning";

    if (rating < 3) color = "text-danger";

    let stars = [];

    for (let index = 1; index <= 5; index++) {
      let icon = "star";

      if (index <= rating) icon = "star";
      else if (index - rating <= 0.5) icon = "star-half-stroke";
      else icon = ["far", "star"];

      stars.push(
        <FontAwesomeIcon icon={icon} className={color}></FontAwesomeIcon>
      );
    }

    return stars;
  };

  return (
    <span title={rating}>
      <>{getStars()}</>
        {addRatingAside && (
            <span className="ml-2">{rating.toFixed(1)}</span>
        )}
    </span>
  );
}
