import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const LoadingButton = ({
  loading,
  color,
  className,
  children,
  size,
  onClick,
}) => {
  const [localLoading, setLocalLoading] = useState(loading);

  useEffect(() => {
    setLocalLoading(loading);
  }, [loading]);

  const waitForOnClick = async () => {
    setLocalLoading(true);
    try {
      await onClick();
    } catch (error) {
      console.error(error);
    }
    setLocalLoading(false);
  };

  return (
    <Button
      disabled={localLoading}
      color={color}
      className={className}
      size={size}
      onClick={waitForOnClick}
    >
      {localLoading && <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>}
      {children}
    </Button>
  );
};

export default LoadingButton;
