import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import CustomBadge from "../ui/CustomBadge";

const PersonalBadges = ({ container, user }) => {
  const [data, setData] = useState([]);
  const es = new EnterpriseService();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await es.getUserTags(user.id);
    setData(result);
  };

  const renderTag = (tag) => {
    let icon;
    let label;
    let color = "";
    let backgroundColor = null;

    if (user.hide_tags != null && user.hide_tags.includes(tag.tag)) return;

    switch (tag.tag) {
      case "driver_style":
        color = "primary";
        label = container.localeManager.strings[tag.value];
        if (tag.value == "realistic") {
          icon = "couch";
        } else {
          icon = "rocket";
        }
        break;
      case "driver_type":
        label = container.localeManager.strings[tag.value];
        color = "info";
        if (tag.value == "sp") {
          icon = "address-book";
        } else {
          icon = "users-rectangle";
        }
        break;
      case "truck_lover_ats":
      case "truck_lover_ets2":
        color = "";
        label = container.localeManager.formatString(
          container.localeManager.strings.truckLover,
          tag.value
        );
        icon = "truck";
        break;
      case "long_hauler":
        label = container.localeManager.strings.long_hauler;
        color = "success";
        icon = "ruler";
        break;
      case "workaholic":
        label = container.localeManager.strings.workhaolic;
        color = "danger";
        icon = "fire";
        break;
      case "trailer_addiction":
        label = container.localeManager.formatString(
          container.localeManager.strings.addictedTo,
          tag.value
        );
        color = "white";
        icon = "trailer";
        break;
    }

    if (tag.company_id) {
      label = tag.name;
      icon = tag.icon;
      backgroundColor = tag.color;
    }

    return (
      <CustomBadge
        color={color}
        light={true}
        size="sm"
        className="me-3 mb-2"
        style={backgroundColor ? { backgroundColor: backgroundColor } : null}
      >
        <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
        {label}
      </CustomBadge>
    );
  };

  return <>{data.map((m) => renderTag(m))}</>;
};

export default PersonalBadges;
