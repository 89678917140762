import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Table,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { EnterpriseContext } from "./EnterpriseContext";
import Breadcrumbs from "../../web/components/Breadcrumbs";
import VTCMenu from "../../web/components/VTCMenu";
import LoadingButton from "../ui/LoadingButton";
import { toast, ToastContainer } from "react-toastify";
import { confirmWrapper } from "../ui/askConfirm";
import LoadingOverlay from "react-loading-overlay";

export default function Ranks({ container }) {
  const [ranks, setRanks] = useState([]);
  const { globalContext } = useContext(EnterpriseContext);
  const es = new EnterpriseService();
  const [currentRank, setCurrentRank] = useState(null);
  const [showEditDialog, toggleEditDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const company = globalContext.company;
  const member = globalContext.member;

  useEffect(() => {
    getRanks();
  }, []);

  const getRanks = async () => {
    setLoading(true);
    if (es.can(member, "members.manage_roles")) {
      const result = await es.getCompanyRanks();
      if (!result.message) setRanks(result);
    }
    setLoading(false);
  };

  const getRoleColoredSpan = (rank) => {
    return <span style={{ color: rank.color }}>{rank.name}</span>;
  };

  useEffect(() => {
    if (!showEditDialog) {
      setCurrentRank(null);
    }
  }, [showEditDialog]);

  useEffect(() => {
    if (currentRank != null) {
      toggleEditDialog(true);
    }
  }, [currentRank]);

  const [discordRoles, setDiscordRoles] = useState(null);

  useEffect(() => {
    getDiscordRoles();
  }, []);

  const getDiscordRoles = async () => {
    try {
      const result = await es.getCompanyDiscordServerRoles(company);

      if (result.success) {
        setDiscordRoles(result.data);
      }
    } catch (error) {}
  };

  const saveRole = async () => {
    var result = null;

    if (currentRank.id) {
      result = await es.updateCompanyRank(currentRank);
    } else {
      result = await es.createCompanyRank(currentRank);
    }

    if (result.success) {
      toast.success(container.localeManager.strings.dataUpdated);
      toggleEditDialog(false);
      getRanks();
    } else {
      toast.error(result.message);
    }
  };

  const deleteRank = async (rank) => {
    if (
      await confirmWrapper(container.localeManager.strings.deleteRoleConf, {
        container: container,
      })
    ) {
      const result = await es.deleteCompanyRank(rank.id);
      if (result.success) {
        getRanks();
      } else {
        toast.error(result.message);
      }
    }
  };

  const massAssign = async () => {
    if (
      await confirmWrapper(container.localeManager.strings.areYouSure, {
        container: container,
      })
    ) {
      setLoading(true);
      try {
        await es.massAssignRanks();
        toast.success(container.localeManager.strings.dataUpdated);
        getRanks();
      } catch (error) {
        toast.error(error.message);
      }
      setLoading(false);
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row noGutters>
        <Col sm="auto">
          <VTCMenu active={"/company/ranks"} container={container}></VTCMenu>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <Breadcrumbs
                breadcrumbs={[
                  {
                    to: "/vtchub",
                    title: container.localeManager.strings.vtcHub,
                  },
                  {
                    to: "/company/members",
                    title: container.localeManager.strings.members,
                  },
                  {
                    title: container.localeManager.strings.ranks,
                  },
                ]}
              ></Breadcrumbs>
              <h5 className="d-flex w-100 mb-5 justify-content-between align-items-center">
                <div>{container.localeManager.strings.ranks}</div>
                <div>
                  {es.can(member, "members.manage_roles") && (
                    <>
                      {ranks.length > 0 && (
                        <Button
                          color="primary"
                          className="me-3"
                          size="sm"
                          onClick={massAssign}
                        >
                          {container.localeManager.strings.massAssignRanks}
                        </Button>
                      )}

                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => setCurrentRank({})}
                      >
                        <FontAwesomeIcon icon="add"></FontAwesomeIcon>{" "}
                        {container.localeManager.strings.createRank}
                      </Button>
                    </>
                  )}
                </div>
              </h5>
              <Table
                responsive
                striped
                size="sm"
                className="table-row-dashed table-row-gray-300 align-middle gs-0"
                hover
              >
                <thead>
                  <tr className="fw-bolder text-gray-700 bg-light">
                    <th scope="col" className="ps-4">
                      {container.localeManager.strings.name}
                    </th>
                    <th scope="col" className="ps-4">
                      {container.localeManager.strings.fromDistanceKm}
                    </th>
                    <th>{container.localeManager.strings.members}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {ranks.map((m) => {
                    return (
                      <tr>
                        <td className="ps-2">{getRoleColoredSpan(m)}</td>
                        <td className="ps-2">{m.from_distance_km}</td>
                        <td>{m.users_count}</td>
                        <td className="text-end">
                          <Button
                            title={container.localeManager.strings.edit}
                            onClick={() => setCurrentRank(m)}
                            className="btn-icon w-30px h-30px ms-auto"
                            size="sm"
                            color="active-light-primary"
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              className="me-0"
                            ></FontAwesomeIcon>
                          </Button>
                          <Button
                            title={container.localeManager.strings.delete}
                            disabled={m.owner}
                            className="btn-icon w-30px h-30px ms-auto"
                            onClick={() => deleteRank(m)}
                            size="sm"
                            color="active-light-danger"
                          >
                            <FontAwesomeIcon
                              icon="trash-alt"
                              className="me-0"
                            ></FontAwesomeIcon>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {showEditDialog && (
                <Modal
                  size="lg"
                  className="big-modal"
                  wrapClassName="big-modal-wrap"
                  modalClassName="big-modal-container"
                  contentClassName="big-modal-content"
                  isOpen={showEditDialog}
                  toggle={() => toggleEditDialog(false)}
                >
                  <ModalHeader
                    toggle={() => toggleEditDialog(false)}
                    close={
                      <Button
                        color="active-light-primary"
                        className="btn-icon btn-sm w-30px h-30px ms-auto"
                        onClick={() => toggleEditDialog(false)}
                      >
                        <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                      </Button>
                    }
                  >
                    {container.localeManager.strings.rankManagement}
                  </ModalHeader>
                  <ModalBody>
                    {currentRank != null && (
                      <div>
                        <FormGroup row>
                          <Col>
                            <Label>
                              {container.localeManager.strings.name} *
                            </Label>
                            <Input
                              type="text"
                              value={currentRank.name}
                              onChange={(e) =>
                                setCurrentRank({
                                  ...currentRank,
                                  name: e.target.value,
                                })
                              }
                            ></Input>
                          </Col>
                          <Col>
                            <Label>
                              {container.localeManager.strings.color}
                            </Label>
                            <Input
                              type="color"
                              placeholder={
                                container.localeManager.strings.roleColor
                              }
                              value={currentRank.color}
                              onChange={(e) =>
                                setCurrentRank({
                                  ...currentRank,
                                  color: e.target.value,
                                })
                              }
                            ></Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col>
                            <Label>
                              {container.localeManager.strings.fromDistanceKm} *
                            </Label>
                            <Input
                              type="number"
                              min="0"
                              value={currentRank.from_distance_km}
                              onChange={(e) =>
                                setCurrentRank({
                                  ...currentRank,
                                  from_distance_km: e.target.value,
                                })
                              }
                            ></Input>
                          </Col>
                          <Col>
                            {discordRoles &&
                              discordRoles.bot_in_server &&
                              discordRoles.roles && (
                                <FormGroup row>
                                  <Col>
                                    <Label>Discord Role</Label>
                                    <Input
                                      type="select"
                                      value={currentRank.discord_role_id}
                                      onChange={(e) =>
                                        setCurrentRank({
                                          ...currentRank,
                                          discord_role_id: e.target.value,
                                        })
                                      }
                                    >
                                      <option value={null}></option>
                                      {discordRoles.roles.map((m) => {
                                        return (
                                          <option value={m.id}>{m.name}</option>
                                        );
                                      })}
                                    </Input>
                                  </Col>
                                  <Col></Col>
                                </FormGroup>
                              )}
                          </Col>
                        </FormGroup>

                        <FormGroup className="mt-3">
                          <LoadingButton
                            size="sm"
                            color="primary"
                            onClick={() => saveRole()}
                          >
                            {container.localeManager.strings.save}
                          </LoadingButton>
                        </FormGroup>
                      </div>
                    )}
                  </ModalBody>
                </Modal>
              )}
            </CardBody>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Card>
        </Col>
      </Row>
    </LoadingOverlay>
  );
}
