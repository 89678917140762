/* global overwolf */

import config from "../../config";
import cacheKeys from "../constants/cacheKeys";
import truckyService from "./trucky-service";

var qs = require("qs");

class EnterpriseService {
  constructor() {
    this.db = null;
    this.db_version = 3;
    this.currentJob = null;
    this.lastJob = null;
    this.jobInitialized = false;
    this.db_is_opening = false;
    this.db_is_open = false;

    this.member = null;
    this.company = null;
    this.plugin = null;

    this.useNewTrackingDB = false;

    this.warp = null;

    this.sessionJobTrackingDisabled = false;

    this.completedJobs = [];
  }

  async executeRequest(url, method = "GET", data = null, auth = false) {
    try {
      var fetchInit = {
        headers: {
          "content-type": "application/json",
          "User-Agent": "Trucky Overlay",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: method,
      };

      if (auth) {
        fetchInit.headers["x-access-token"] = truckyService.getToken();
      }

      if (method != "GET" && data != null) {
        fetchInit.body = JSON.stringify(data);
      }

      var response = await fetch(
        `${config.ENTERPRISE_URL}/api${url}`,
        fetchInit
      );

      return await response.json();
    } catch (ex) {
      truckyService.writeLog(
        `Error requesting to Enterprise API: ${ex.message}`
      );
      return null;
    }
  }

  async uploadFile(url, data, auth = false) {
    try {
      var fetchInit = {
        method: "POST",
        body: data,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      };

      if (auth) {
        fetchInit.headers["x-access-token"] = localStorage.token;
      }

      var response = await fetch(
        `${config.ENTERPRISE_URL}/api${url}`,
        fetchInit
      );

      return await response.json();
    } catch (ex) {
      truckyService.writeLog(
        `Error requesting to Enterprise API: ${ex.message}`
      );
      return null;
    }
  }

  async loadEnterpriseMemberData(force = false) {
    if (truckyService.getToken() && (this.member == null || force)) {
      truckyService.writeLog(
        "-------------------------------- ENTERPRISE --------------------------------"
      );

      try {
        this.member = await this.me();

        if (this.member == null)
          throw new Error(
            "MEMBER IS NULL - PROBABLY NO INTERNET CONNECTION, TRYING TO RECOVER FROM CACHE"
          );

        if (
          this.member != null &&
          this.member.company_id != null &&
          this.member.company != null
        ) {
          this.company = this.member.company;
        }

        //truckyService.setCacheData(cacheKeys.ENTERPRISE_MEMBER, this.member);
      } catch (ex) {
        truckyService.writeLog(
          "!!!! ERROR LOADING ENTERPRISE MEMBER - JOB TRACKING WONT WORK !!!!"
        );

        if (truckyService.existsInCache(cacheKeys.ENTERPRISE_MEMBER)) {
          this.member = truckyService.getDataFromCache(
            cacheKeys.ENTERPRISE_MEMBER
          );

          if (
            this.member != null &&
            this.member.company_id != null &&
            this.member.company != null
          ) {
            this.company = this.member.company;
          }
        }
      }

      if (this.member != null)
        truckyService.writeLog(
          `Enterprise Member loaded: ${this.member.id} - ${this.member.name}`
        );

      if (this.company != null)
        truckyService.writeLog(
          `Enterprise Company loaded: ${this.company.id} - ${this.company.name}`
        );
    }
  }

  memberCanDispatch() {
    if (
      this.member != null &&
      this.company != null &&
      this.can(this.member, "company.dispatcher")
    )
      return true;

    return false;
  }

  hasCompany() {
    return this.member != null && this.company != null;
  }

  async ping() {
    var result = await this.executeRequest("/v1/ping", "GET", null, false);
    return result;
  }

  async me() {
    var result = await this.executeRequest(
      "/v1/user/me?include=steam,active_patron",
      "GET",
      null,
      true
    );
    return result;
  }

  async checkTmpCompany() {
    var result = await this.executeRequest(
      "/v1/company/truckersmp/check",
      "GET",
      null,
      true
    );
    return result;
  }

  async getCompanies(searchParameters, pagination) {
    var result = await this.executeRequest(
      `/v1/companies?${qs.stringify(searchParameters)}&${qs.stringify(
        pagination
      )}`
    );
    return result;
  }

  async getCompaniesLeaderboards(searchParameters, pagination) {
    var result = await this.executeRequest(
      `/v1/companies/leaderboards?${qs.stringify(
        searchParameters
      )}&${qs.stringify(pagination)}`
    );
    return result;
  }

  async getCompany(id) {
    var result = await this.executeRequest(`/v1/company/${id}`);
    return result;
  }

  async getCompanyBySlug(name) {
    var result = await this.executeRequest(`/v1/company/bySlug?slug=${name}`);
    return result;
  }

  async getCompanyStats(id) {
    var result = await this.executeRequest(
      `/v1/company/${id}/stats`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getCompanyStatsMonthly(id, month, year) {
    var result = await this.executeRequest(
      `/v1/company/${id}/stats/monthly?month=${month}&year=${year}`
    );
    return result;
  }

  async getCompanyStatsYearly(id, year) {
    var result = await this.executeRequest(
      `/v1/company/${id}/stats/yearly?year=${year}`
    );
    return result;
  }

  async getMembersCompanyStatsMonthly(id, month, year) {
    var result = await this.executeRequest(
      `/v1/company/${id}/stats/members/monthly?month=${month}&year=${year}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getMembersCompanyStatsYearly(id, year) {
    var result = await this.executeRequest(
      `/v1/company/${id}/stats/members/yearly?year=${year}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getEcoCompanyStatsMonthly(id, month, year) {
    var result = await this.executeRequest(
      `/v1/company/${id}/stats/eco/monthly?month=${month}&year=${year}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getEcoCompanyStatsYearly(id, year) {
    var result = await this.executeRequest(
      `/v1/company/${id}/stats/eco/yearly?year=${year}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async createCompanyFromTmp() {
    var result = await this.executeRequest(
      "/v1/company/truckersmp/create",
      "POST",
      null,
      true
    );
    return result;
  }

  async syncMembers(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/truckersmp/sync`,
      "GET",
      null,
      true
    );
    return result;
  }

  async searchCities(search) {
    var result = await this.executeRequest(`/v1/geo/cities?search=${search}`);
    return result;
  }

  async getCountries() {
    var result = await this.executeRequest(`/v1/geo/countries/all`);
    return result;
  }

  async getLanguages() {
    var result = await this.executeRequest(`/v1/geo/languages`);
    return result;
  }

  async createCompany(company) {
    var result = await this.executeRequest(
      `/v1/companies/`,
      "POST",
      company,
      true
    );
    return result;
  }

  async updateCompany(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}`,
      "PATCH",
      company,
      true
    );
    return result;
  }

  async getGarages(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/garages`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getGarage(company_id, garage_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/garage/${garage_id}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getGarageVehicles(company_id, garage_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/garage/${garage_id}/vehicles`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getGarageMechanics(company_id, garage_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/garage/${garage_id}/mechanics`,
      "GET",
      null,
      true
    );
    return result;
  }

  async hireMechanic(company_id, garage_id, mechanic) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/garage/${garage_id}/mechanics`,
      "POST",
      mechanic,
      true
    );
    return result;
  }

  async fireMechanic(company_id, garage_id, mechanic) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/garage/${garage_id}/mechanic/${mechanic.id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async generateMechanic(countPerType) {
    var result = await this.executeRequest(
      `/v1/mechanic/generate?count=${countPerType}`,
      "GET",
      null
    );
    return result;
  }

  async buyNewGarage(company, garage) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/garages`,
      "POST",
      garage,
      true
    );
    return result;
  }

  async getVehicles(company, searchParameters) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/vehicles?${qs.stringify(searchParameters)}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getMaintenanceAlerts(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/vehicles/alerts`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getMembers(company, pagination, searchParameters, include = "") {
    if (searchParameters == undefined)
      searchParameters = { name: "", role_id: "", user_id: "" };

    var result = await this.executeRequest(
      `/v1/company/${company.id}/members?${qs.stringify(
        pagination
      )}&${qs.stringify(searchParameters)}&include=${include}`,
      "GET"
    );
    return result;
  }

  async getMemberVehicles(user) {
    var result = await this.executeRequest(
      `/v1/user/${user.id}/vehicles`,
      "GET"
    );
    return result;
  }

  async getMemberVehiclesWithMaintenance(user) {
    var result = await this.executeRequest(
      `/v1/user/${user.id}/vehicles/withMaintenance`,
      "GET"
    );
    return result;
  }

  async getMemberJobs(
    user,
    pagination,
    month,
    year,
    period = "monthly",
    date,
    searchParameters
  ) {
    var result = await this.executeRequest(
      `/v1/user/${user.id}/jobs?${qs.stringify(
        searchParameters
      )}&${qs.stringify(
        pagination
      )}&month=${month}&year=${year}&period=${period}&date=${date}`,
      "GET"
    );
    return result;
  }

  async getMemberJobsNoPeriod(user, pagination) {
    var result = await this.executeRequest(
      `/v1/user/${user.id}/jobs?${qs.stringify(pagination)}`,
      "GET"
    );
    return result;
  }

  async getCompanyRoles(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/roles`,
      "GET"
    );
    return result;
  }

  async getPermissions() {
    var result = await this.executeRequest(`/v1/permissions`, "GET");
    return result;
  }

  async getJobs(company, top = 0, pagination, searchParameters) {
    if (searchParameters == undefined)
      searchParameters = { game_id: "", game_mode: "", user_id: "" };

    var result = await this.executeRequest(
      `/v1/company/${company.id}/jobs?top=${top}&${qs.stringify(
        pagination
      )}&${qs.stringify(searchParameters)}`,
      "GET"
    );
    return result;
  }

  createDownloadJobsUrl(company, searchParameters) {
    const parameters = { ...searchParameters, mode: "export" };
    const url =
      config.ENTERPRISE_URL +
      `/api/v1/company/${company.id}/jobs?${qs.stringify(parameters)}`;
    return url;
  }

  createDownloadUserJobsUrl(user_id, searchParameters) {
    const parameters = { ...searchParameters, mode: "export" };
    const url =
      config.ENTERPRISE_URL +
      `/api/v1/user/${user_id}/jobs?${qs.stringify(parameters)}`;
    return url;
  }

  async getGames() {
    var result = await this.executeRequest(`/v1/common/games`, "GET");
    return result;
  }

  async getBrands(vehicleType = "truck") {
    var result = await this.executeRequest(
      `/v1/dealer/brands?vehicleType=${vehicleType}`,
      "GET"
    );
    return result;
  }

  async getModels(brand, vehicleType = "truck") {
    var result = await this.executeRequest(
      `/v1/dealer/brand/${brand.id}/modelsWithCompanyPrice?type=${vehicleType}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async buyNewVehicle(company, vehicle) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/vehicles`,
      "POST",
      vehicle,
      true
    );
    return result;
  }

  async deleteGarage(company, garage) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/garage/${garage.id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async upgradeGarage(company, garage, newPlaces) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/garage/${garage.id}/upgrade`,
      "PATCH",
      { places: newPlaces },
      true
    );
    return result;
  }

  async deleteVehicle(company, vehicle) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/vehicle/${vehicle.id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async updateVehicle(company, vehicle) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/vehicle/${vehicle.id}`,
      "PATCH",
      vehicle,
      true
    );
    return result;
  }

  async createRole(company, role) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/roles`,
      "POST",
      role,
      true
    );
    return result;
  }

  async updateRole(company, role) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/role/${role.id}`,
      "PATCH",
      role,
      true
    );
    return result;
  }

  async deleteRole(company, role) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/role/${role.id}`,
      "DELETE",
      role,
      true
    );
    return result;
  }

  async updateUser(user) {
    var result = await this.executeRequest(
      `/v1/user/${user.id}`,
      "PATCH",
      user,
      true
    );
    return result;
  }

  async updateProfile(user) {
    var result = await this.executeRequest(`/v2/user/me`, "PATCH", user, true);
    return result;
  }

  async completeRegistration(data) {
    var result = await this.executeRequest(
      `/v1/user/complete`,
      "PATCH",
      data,
      true
    );
    return result;
  }

  async leaveCompany() {
    var result = await this.executeRequest(
      `/v1/user/company/leave`,
      "PATCH",
      {},
      true
    );
    return result;
  }

  async uploadNewUserAvatar(user, file) {
    const formData = new FormData();
    formData.append("avatar", file);
    var result = await this.uploadFile(
      `/v1/user/${user.id}/avatar`,
      formData,
      true
    );
    return result;
  }

  can(member, permission) {
    var permissionsHierarchy = permission.split(".");
    var groupPermission = `${permissionsHierarchy[0]}.*`;

    if (member.role != null) {
      if (member.role.owner) return true;

      if (member.role.permissions != null) {
        if (
          member.role.permissions.find((m) =>
            m.capabilities.includes(groupPermission)
          ) != null
        )
          return true;

        if (
          member.role.permissions.find((m) =>
            m.capabilities.includes(permission)
          ) != null
        )
          return true;
      }
    }

    return false;
  }

  async uploadCompanyAsset(company, field, file) {
    const formData = new FormData();
    formData.append("field", field);
    formData.append(field, file);
    var result = await this.uploadFile(
      `/v1/company/${company.id}/upload`,
      formData,
      true
    );
    return result;
  }

  async removeMember(company, user) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/user/${user.id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async kickMember(company, user) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/user/${user.id}/kick`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async deleteCompany(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async applyToCompany(company, application) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/applications`,
      "POST",
      application,
      true
    );
    return result;
  }

  async getApplications(company, pagination, parameters) {
    var result = await this.executeRequest(
      `/v2/company/${company.id}/applications?${qs.stringify(
        pagination
      )}&${qs.stringify(parameters)}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getUserApplications() {
    var result = await this.executeRequest(
      `/v1/user/me/applications`,
      "GET",
      null,
      true
    );
    return result;
  }

  async deleteUserApplication(id) {
    var result = await this.executeRequest(
      `/v1/user/application/${id}}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async getApplicationDetail(company, id) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/application/${id}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async updateApplication(company, application) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/application/${application.id}`,
      "PATCH",
      application,
      true
    );
    return result;
  }

  async acceptApplication(company, application) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/application/${application.id}/accept`,
      "POST",
      null,
      true
    );
    return result;
  }

  async rejectApplication(company, application) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/application/${application.id}/reject`,
      "POST",
      null,
      true
    );
    return result;
  }

  async getTransactions(company, pagination) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/transactions?${qs.stringify(pagination)}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getBalanceStats(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/transactions/stats`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getMaintenances(company, pagination) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/maintenances?${qs.stringify(pagination)}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getJob(id) {
    var result = await this.executeRequest(`/v1/job/${id}`, "GET", null, false);
    return result;
  }

  async deleteJob(id) {
    var result = await this.executeRequest(
      `/v1/job/${id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async getMyNotifications() {
    var result = await this.executeRequest(
      `/v1/user/me/notifications`,
      "GET",
      null,
      true
    );
    return result;
  }

  async markNotificationAsRead(notification) {
    var result = await this.executeRequest(
      `/v1/user/me/notification/${notification.id}/read`,
      "GET",
      null,
      true
    );
    return result;
  }

  async markAllNotificationsAdRead() {
    var result = await this.executeRequest(
      `/v1/user/me/notifications/readall`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getVehicle(company, id) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/vehicle/${id}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getUser(id) {
    var result = await this.executeRequest(
      `/v2/user/${id}`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getUserLevelInfo(id) {
    var result = await this.executeRequest(
      `/v1/user/${id}/levelInfo`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getUserLeaderboards(id) {
    var result = await this.executeRequest(
      `/v1/user/${id}/stats/leaderboards`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getUserBySteam(id) {
    var result = await this.executeRequest(
      `/v1/user/steam/${id}`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getUserEcoStats(user, month, year) {
    var result = await this.executeRequest(
      `/v1/user/${user.id}/stats/eco/monthly?month=${month}&year=${year}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getUserMilesStatsYearly(user, year) {
    var result = await this.executeRequest(
      `/v1/user/${user.id}/stats/miles/yearly?year=${year}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getUserEcoStatsYearly(user, year) {
    var result = await this.executeRequest(
      `/v1/user/${user.id}/stats/eco/yearly?year=${year}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getUserEcoStatsByDate(user, period, month, year, date, week) {
    var result = await this.executeRequest(
      `/v1/user/${user.id}/stats/eco/period?month=${month}&year=${year}&period=${period}&date=${date}&week=${week}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getUserMilesStatsByDate(user, period, month, year, date, week) {
    var result = await this.executeRequest(
      `/v1/user/${user.id}/stats/miles/period?month=${month}&year=${year}&period=${period}&date=${date}&week=${week}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getUserMilesStats(user, month, year) {
    var result = await this.executeRequest(
      `/v1/user/${user.id}/stats/miles/monthly?month=${month}&year=${year}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getCompanyAwards(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/awards`,
      "GET"
    );
    return result;
  }

  async getCompanyAwardsMonthly(company, month, year) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/awards/monthly?month=${month}&year=${year}`,
      "GET"
    );
    return result;
  }

  async getMembersBadgesMonthly(company, month, year) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/members/badges?month=${month}&year=${year}`,
      "GET"
    );
    return result;
  }

  async getUserBadgesMonthly(user, month, year) {
    var result = await this.executeRequest(
      `/v1/user/${user.id}/badges/monthly?month=${month}&year=${year}`,
      "GET"
    );
    return result;
  }

  async getUserBadges(user) {
    var result = await this.executeRequest(`/v1/user/${user.id}/badges`, "GET");
    return result;
  }

  async getOwnedModels(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/vehicles/models`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getMarket(game_id, filters) {
    var result = await this.executeRequest(
      `/v1/economy/market/${game_id}?${qs.stringify(filters)}`,
      "GET"
    );
    return result;
  }

  async updateUserBannerSettings(settings) {
    var result = await this.executeRequest(
      `/v1/user/me/bannerSettings`,
      "PATCH",
      settings,
      true
    );
    return result;
  }

  async getMatchingVehicle(job) {
    var result = await this.executeRequest(
      `/v1/job/${job.id}/vehicleModel`,
      "GET",
      null,
      false
    );
    return result;
  }

  async reportJob(job, reportInfo) {
    var result = await this.executeRequest(
      `/v1/job/${job.id}/report`,
      "POST",
      reportInfo,
      true
    );
    return result;
  }

  async claimCompanyToken() {
    var result = await this.executeRequest(
      `/v1/company/token/claim`,
      "GET",
      {},
      true
    );
    return result;
  }

  async assignVehicleToMechanic(company, data) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/maintenance`,
      "POST",
      data,
      true
    );
    return result;
  }

  async getUserLogs(id) {
    var result = await this.executeRequest(
      `/v1/user/${id}/logs`,
      "GET",
      null,
      false
    );
    return result;
  }

  async sendApplicationContactMessage(company, application, message) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/application/${application.id}/contact`,
      "POST",
      message,
      true
    );
    return result;
  }

  async getAchievements() {
    var result = await this.executeRequest(
      `/v1/achievements`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getValidCompanyAchievements(id) {
    var result = await this.executeRequest(
      `/v1/company/${id}/achievements/valid`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getAllCompanyAchievements(id, page = 1) {
    var result = await this.executeRequest(
      `/v1/company/${id}/achievements?page=${page}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getUsersLeaderboards(filters, pagination) {
    var result = await this.executeRequest(
      `/v1/users/leaderboards?${qs.stringify(filters)}&${qs.stringify(
        pagination
      )}`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getCompanySalariesSimulation(id, salary_type, base_member_salary) {
    var result = await this.executeRequest(
      `/v1/company/${id}/salaries`,
      "POST",
      { salary_type: salary_type, base_member_salary: base_member_salary },
      true
    );
    return result;
  }

  async resetCompany(id) {
    var result = await this.executeRequest(
      `/v1/company/${id}/reset`,
      "POST",
      null,
      true
    );
    return result;
  }

  async changeCompanyOwner(company, data) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/owner`,
      "PATCH",
      data,
      true
    );
    return result;
  }

  async getAggregatedStats(id) {
    var result = await this.executeRequest(
      `/v1/user/${id}/stats/aggregated`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getAggregatedStatsPerGame(id, game_id) {
    var result = await this.executeRequest(
      `/v1/user/${id}/stats/aggregated?game_id=${game_id}`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getCompanyAggregatedStats(id) {
    var result = await this.executeRequest(
      `/v1/company/${id}/stats/aggregated`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getJobsGroupedByDate(id, filters) {
    var result = await this.executeRequest(
      `/v1/user/${id}/stats/chart/period?${qs.stringify(filters)}`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getCompanyJobsGroupedByDate(id, filters) {
    var result = await this.executeRequest(
      `/v1/company/${id}/stats/chart/period?${qs.stringify(filters)}`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getOverallStats() {
    var result = truckyService.getCacheDataWithExpiration("overall_stats");
    if (result == null) {
      result = await this.executeRequest(
        `/v1/common/stats`,
        "GET",
        null,
        false
      );
      if (process.env != "development")
        truckyService.setCacheDataWithExpiration(
          "overall_stats",
          result,
          86400
        );
    }
    return result;
  }

  async deleteAccount() {
    var result = await this.executeRequest(`/v1/user`, "DELETE", null, true);
    return result;
  }

  async resetAccount() {
    var result = await this.executeRequest(
      `/v1/user/reset`,
      "PATCH",
      null,
      true
    );
    return result;
  }

  async getUserSubscriptions(getAccountManagementUri = false) {
    var result = await this.executeRequest(
      `/v1/me/subscriptions?getAccountManagementUri=${getAccountManagementUri}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getSubscribeLink(name, quantity, country, premium) {
    var result = await this.executeRequest(
      `/v1/subscriptions`,
      "POST",
      { name: name, quantity: quantity, country: country },
      true
    );
    return result;
  }

  async createSubscriptionServiceAccount(data) {
    var result = await this.executeRequest(
      `/v1/subscriptions/account`,
      "POST",
      data,
      true
    );
    return result;
  }

  async getSubscriptionStatus(name) {
    var result = await this.executeRequest(
      `/v1/subscription/status`,
      "POST",
      { name: name },
      true
    );
    return result;
  }

  async getAvailableSubscriptions() {
    var result = await this.executeRequest(
      `/v1/subscriptions/available`,
      "GET",
      null,
      false
    );
    return result;
  }

  async cancelSubscription(name) {
    var result = await this.executeRequest(
      `/v1/subscriptions`,
      "DELETE",
      { name: name },
      true
    );
    return result;
  }

  async resumeSubscription(name) {
    var result = await this.executeRequest(
      `/v1/subscriptions/resume`,
      "POST",
      { name: name },
      true
    );
    return result;
  }

  async updateSubscription(name, quantity) {
    var result = await this.executeRequest(
      `/v1/subscriptions`,
      "PATCH",
      { name: name, quantity: quantity },
      true
    );
    return result;
  }

  async getUserTags(id) {
    var result = await this.executeRequest(
      `/v1/user/${id}/tags`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getUserTags(id) {
    var result = await this.executeRequest(
      `/v1/user/${id}/tags`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getCompanyTags(company_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/tags`,
      "GET",
      null,
      true
    );
    return result;
  }

  async createCompanyTag(company_id, data) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/tags`,
      "POST",
      data,
      true
    );
    return result;
  }

  async updateCompanyTag(company_id, data) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/tag/${data.id}`,
      "PATCH",
      data,
      true
    );
    return result;
  }

  async deleteCompanyTag(company_id, tag_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/tag/${tag_id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async associateCompanyTagsToUser(company_id, user_id, tags) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/tags/${user_id}/associate`,
      "POST",
      { tags: tags },
      true
    );
    return result;
  }

  async getUserDedicatedServers() {
    var result = await this.executeRequest(
      `/v1/me/dedicated_servers`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getDedicatedServerConfiguration(id) {
    var result = await this.executeRequest(
      `/v1/hosting/servers/${id}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async createDedicatedServerConfiguration(data) {
    var result = await this.executeRequest(
      `/v1/hosting/servers`,
      "POST",
      data,
      true
    );
    return result;
  }

  async updateDedicatedServerConfiguration(data) {
    var result = await this.executeRequest(
      `/v1/hosting/servers/${data.id}`,
      "PATCH",
      data,
      true
    );
    return result;
  }

  async deleteDedicatedServerConfiguration(id) {
    var result = await this.executeRequest(
      `/v1/hosting/servers/${id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async provisionDedicatedServerConfiguration(id) {
    var result = await this.executeRequest(
      `/v1/hosting/servers/${id}/provision`,
      "POST",
      null,
      true
    );
    return result;
  }

  async getRegions() {
    var result = await this.executeRequest(
      `/v1/hosting/regions`,
      "GET",
      null,
      false
    );
    return result;
  }

  async uploadDedicatedServerConfiguration(server, field, file) {
    const formData = new FormData();
    formData.append("field", field);
    formData.append(field, file);
    var result = await this.uploadFile(
      `/v1/hosting/servers/${server.id}/upload`,
      formData,
      true
    );
    return result;
  }

  async restartServer(id) {
    var result = await this.executeRequest(
      `/v1/hosting/servers/${id}/restart`,
      "POST",
      null,
      true
    );
    return result;
  }

  async stopServer(id) {
    var result = await this.executeRequest(
      `/v1/hosting/servers/${id}/stop`,
      "POST",
      null,
      true
    );
    return result;
  }

  async getCompanyServers(id) {
    var result = await this.executeRequest(
      `/v1/company/${id}/servers`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getAvailableLoans() {
    var result = await this.executeRequest(`/v1/economy/loans`, "GET");
    return result;
  }

  async companyLoan(company_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/loans`,
      "GET"
    );
    return result;
  }

  async requestLoan(company_id, plan) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/loans`,
      "POST",
      plan,
      true
    );
    return result;
  }

  async getCompanyLoans(company_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/loans`,
      "GET",
      null,
      true
    );
    return result;
  }

  async repayLoan(company_id, loan_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/loans/${loan_id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async getFeed(company_id, searchParameters) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/feeds?${qs.stringify(searchParameters)}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getPublishedFeed(company_id, searchParameters) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/feeds/published?${qs.stringify(
        searchParameters
      )}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getFeedDetail(company_id, id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/feeds/${id}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async createFeed(company_id, data) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/feeds`,
      "POST",
      data,
      true
    );
    return result;
  }

  async updateFeed(company_id, data) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/feeds/${data.id}`,
      "PATCH",
      data,
      true
    );
    return result;
  }

  async deleteFeed(company_id, id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/feeds/${id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async getThreadsUnreadCount() {
    var result = await this.executeRequest(
      `/v1/messenger/threads/unreadCount`,
      "GET",
      null,
      true
    );
    return result;
  }

  async markThreadAsRead(id) {
    var result = await this.executeRequest(
      `/v1/messenger/threads/${id}/markAsRead`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getPersonalThreads() {
    var result = await this.executeRequest(
      `/v1/messenger/threads`,
      "GET",
      null,
      true
    );
    return result;
  }

  async createThread(data) {
    var result = await this.executeRequest(
      `/v1/messenger/threads`,
      "POST",
      data,
      true
    );
    return result;
  }

  async getThread(id) {
    var result = await this.executeRequest(
      `/v1/messenger/threads/${id}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async replyToThread(id, data) {
    var result = await this.executeRequest(
      `/v1/messenger/threads/${id}`,
      "PATCH",
      data,
      true
    );
    return result;
  }

  async updateUserStatus(company_id, data) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/presence`,
      "PATCH",
      data,
      true
    );
    return result;
  }

  async getCompanyPresenceStatus(company_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/presence`,
      "GET",
      null,
      true
    );
    return result;
  }

  async deleteUserPresence(company_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/presence`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async getMembersCompanyStatsByPeriod(id, parameters) {
    var result = await this.executeRequest(
      `/v2/company/${id}/stats/members?${qs.stringify(parameters)}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getPublicPublishedFeed(company_id, searchParameters) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/feeds/public?${qs.stringify(
        searchParameters
      )}`,
      "GET"
    );
    return result;
  }

  async getMessagesUnreadCount() {
    var result = await this.executeRequest(
      `/v1/messenger/unread`,
      "GET",
      null,
      true
    );
    return result;
  }

  async bans(company_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/bans`,
      "GET",
      null,
      true
    );
    return result;
  }

  async addBan(company_id, user_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/bans`,
      "POST",
      { user_id: user_id },
      true
    );
    return result;
  }

  async removeBan(company_id, ban_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/bans/${ban_id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async getJobEvents(job_id) {
    var result = await this.executeRequest(`/v1/job/${job_id}/events`, "GET");
    return result;
  }

  async getDedicatedServerUsers(id) {
    var result = await this.executeRequest(
      `/v1/hosting/servers/${id}/users`,
      "GET",
      null,
      true
    );
    return result;
  }

  async addDedicatedServerUser(id, user) {
    var result = await this.executeRequest(
      `/v1/hosting/servers/${id}/users`,
      "POST",
      { user_id: user.id },
      true
    );
    return result;
  }

  async removeDedicatedServerUser(id, user_id) {
    var result = await this.executeRequest(
      `/v1/hosting/servers/${id}/users/${user_id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async updateDedicatedServerUser(id, user) {
    var result = await this.executeRequest(
      `/v1/hosting/servers/${id}/users/${user.id}`,
      "PATCH",
      user,
      true
    );
    return result;
  }

  async searchUsers(name, matchType = "exact", paginate = false, page = 1) {
    var result = await this.executeRequest(
      `/v1/users?name=${name}&matchType=${matchType}&paginate=${paginate}&page=${page}`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getTransactionDetails(company, transaction) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/transactions/${transaction}/details`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getGameCities(game_id) {
    var result = await this.executeRequest(
      `/v1/game_data/${game_id}/cities`,
      "GET"
    );
    return result;
  }

  async getGameCompanies(game_id) {
    var result = await this.executeRequest(
      `/v1/game_data/${game_id}/companies`,
      "GET"
    );
    return result;
  }

  async getCargos(game_id) {
    var result = await this.executeRequest(
      `/v1/game_data/${game_id}/cargos`,
      "GET"
    );
    return result;
  }

  async getModelsByGame(game_id, brands) {
    var result = await this.executeRequest(
      `/v1/game_data/${game_id}/models?brand_id=${brands}`,
      "GET"
    );
    return result;
  }

  async getBrandsByGame(game_id) {
    var result = await this.executeRequest(
      `/v1/game_data/${game_id}/brands`,
      "GET"
    );
    return result;
  }

  async createCompanyAchievement(company, data) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/achievements`,
      "POST",
      data,
      true
    );
    return result;
  }

  async updateCompanyAchievement(company, data) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/achievements/${data.id}`,
      "PATCH",
      data,
      true
    );
    return result;
  }

  async getCompanyAchievement(company, id) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/achievements/${id}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getCompanyAchievementCompletion(company, id) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/achievements/${id}/completion`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getValidCompanyAchievement(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/achievements/valid`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getValidCompanyAchievementForUser(company, user_id) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/achievements/valid?user_id=${user_id}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async deleteAchievement(company, id) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/achievements/${id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async getWebhooks(company, platform) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/webhooks?platform=${platform}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async createWebhook(company, data) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/webhooks`,
      "POST",
      data,
      true
    );
    return result;
  }

  async updateWebhook(company, data) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/webhooks/${data.id}`,
      "PATCH",
      data,
      true
    );
    return result;
  }

  async deleteWebhook(company, id) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/webhooks/${id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async testWebhook(company, url) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/webhooks/test`,
      "POST",
      { url: url },
      true
    );
    return result;
  }

  async migrateWebhooks(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/webhooks/migrate`,
      "POST",
      null,
      true
    );
    return result;
  }

  async createWebhookSecret(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/webhooks/secret`,
      "POST",
      null,
      true
    );
    return result;
  }

  async getGlobalCompanyStats() {
    var result = await this.executeRequest(`/v1/common/stats/companies`, "GET");
    return result;
  }

  async getDedicatedServerStatus(id) {
    var result = await this.executeRequest(
      `/v1/hosting/servers/${id}/status`,
      "GET"
    );
    return result;
  }

  async getCompaniesHardcoreLeaderboards(searchParameters, pagination) {
    var result = await this.executeRequest(
      `/v1/companies/hardcore?${qs.stringify(searchParameters)}&${qs.stringify(
        pagination
      )}`
    );
    return result;
  }

  async resetCompanyAchievementCompletion(company, id) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/achievements/${id}/reset`,
      "GET",
      null,
      true
    );
    return result;
  }

  async claimClientLoginCode() {
    var result = await this.executeRequest(
      `/v1/user/clientLoginCode`,
      "POST",
      null,
      true
    );
    return result;
  }

  async getGameBetas(game_id) {
    var result = await this.executeRequest(`/v1/common/games/${game_id}/betas`);
    return result;
  }

  async getUsersHardcoreLeaderboards(filters, pagination) {
    var result = await this.executeRequest(
      `/v1/users/leaderboards/hardcore?${qs.stringify(filters)}&${qs.stringify(
        pagination
      )}`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getDispatchByUniqueId(unique_id) {
    var result = await this.executeRequest(
      `/v1/dispatches/byUniqueID?unique_id=${unique_id}`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getUserConnections() {
    var result = await this.executeRequest(
      `/v1/user/me/connections`,
      "GET",
      null,
      true
    );
    return result;
  }

  async unlinkSocialConnection(service, user_id) {
    var result = await this.executeRequest(
      `/v1/user/me/connections`,
      "DELETE",
      { service: service, external_user_id: user_id },
      true
    );
    return result;
  }

  async renewAuth() {
    var result = await this.executeRequest(
      `/v1/user/me/renew`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getUserDiscordServers() {
    var result = await this.executeRequest(
      `/v1/user/me/discordServers`,
      "GET",
      null,
      true
    );
    return result;
  }

  async getCompanyDiscordServerRoles(company) {
    var result = await this.executeRequest(
      `/v1/company/${company.id}/discordRoles`,
      "GET",
      null,
      true
    );
    return result;
  }

  async changeRole(company_id, user_id, role_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/assignRole`,
      "POST",
      { user_id: user_id, role_id: role_id },
      true
    );
    return result;
  }

  async changeRank(company_id, user_id, rank_id) {
    var result = await this.executeRequest(
      `/v1/company/${company_id}/assignRank`,
      "POST",
      { user_id: user_id, rank_id: rank_id },
      true
    );
    return result;
  }

  async checkBotInServer(server_id) {
    var result = await this.executeRequest(
      `/v1/discord/check?guild_id=${server_id}`,
      "GET"
    );
    return result;
  }

  async getDispatchByUniqueOrGroupedUniqueID(uniqueID) {
    var result = await this.executeRequest(
      `/v2/dispatches/byUniqueID?unique_id=${uniqueID}&include=dispatcher,dispatcher.company`,
      "GET",
      null,
      false
    );
    return result;
  }

  async getCompanyRanks() {
    var result = await this.executeRequest(`/v1/ranks`, "GET", null, true);
    return result;
  }

  async getCompanyRank(id) {
    var result = await this.executeRequest(
      `/v1/ranks/${id}`,
      "GET",
      null,
      true
    );
    return result;
  }

  async createCompanyRank(rank) {
    var result = await this.executeRequest(`/v1/ranks`, "POST", rank, true);
    return result;
  }

  async updateCompanyRank(rank) {
    var result = await this.executeRequest(
      `/v1/ranks/${rank.id}`,
      "PUT",
      rank,
      true
    );
    return result;
  }

  async deleteCompanyRank(id) {
    var result = await this.executeRequest(
      `/v1/ranks/${id}`,
      "DELETE",
      null,
      true
    );
    return result;
  }

  async massAssignRanks() {
    var result = await this.executeRequest(
      `/v1/ranks/massAssign`,
      "POST",
      null,
      true
    );
    return result;
  }
}

export default EnterpriseService;
