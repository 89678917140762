import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  UncontrolledTooltip,
} from "reactstrap";
import { MenuComponent } from "../../common/plugins/metronic/components";
import EnterpriseService from "../../common/services/enterprise-service";
import truckyService from "../../common/services/trucky-service";
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import ButtonDropdownMenu from "../../components/ui/ButtonDropdownMenu";
import config from "../../config";
import Cookies from "js-cookie";
import { Form, Link } from "react-router-dom";
import Events from "../../common/constants/events";
import CustomBadge from "../../components/ui/CustomBadge";
import { isTeam } from "../utils";

const Header = ({ container }) => {
  const active = false;
  const es = new EnterpriseService();
  const { globalContext, setGlobalContext } = useContext(EnterpriseContext);
  const navigate = useNavigate();
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [notifications, setNotifications] = useState(
    container.dataService.data.notifications
  );
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.bootstrap();
    }, 500);

    container.eventBus.addListener(
      Events.NOTIFICATIONS_RECEIVED,
      onNotificationReceived
    );

    getMessagesUnreadCount();

    container.eventBus.addListener(
      Events.UNREAD_MESSAGES_COUNT_UPDATE,
      (newCount) => {
        setUnreadMessagesCount(newCount);
      }
    );

    container.eventBus.addListener(Events.MESSAGE_RECEIVED, onMessageReceived);

    if (globalContext.member != null) {
      es.getMyNotifications().then((result) => {
        container.dataService.data.notifications = [];
        setNotifications(result);
      });
    }
  }, []);

  const readNotification = async (notif) => {
    await es.markNotificationAsRead(notif);
    let newNotifications = notifications.filter((m) => m.id != notif.id);
    setNotifications(newNotifications);
    navigate(notif.data.route);
  };

  const readAllNotifications = async () => {
    await es.markAllNotificationsAdRead();
    container.dataService.data.notifications = [];
    setNotifications([]);
  };

  const onNotificationReceived = (notification) => {
    setNotifications([...container.dataService.data.notifications]);
  };

  useEffect(() => {
    if (notifications != null && notifications.length > 0) {
      MenuComponent.createInsance("#notifications-dropdown");
    }
  }, [notifications]);

  const getMessagesUnreadCount = async () => {
    if (container.dataService.enterpriseService.member != null) {
      const result = await es.getMessagesUnreadCount();
      setUnreadMessagesCount(result);
    }
  };

  const onMessageReceived = () => {
    if (location.pathname != "/messenger") {
      setUnreadMessagesCount(unreadMessagesCount + 1);
    }
  };

  const getPersonalMenu = () => {
    const menu = [
      {
        divider: true,
      },
    ];

    if (globalContext.member.email != null) {
      menu.push(
        {
          click: () => navigate(`/profile/edit`),
          title: container.localeManager.strings.accountSettings,
          icon: <FontAwesomeIcon icon="user-cog"></FontAwesomeIcon>,
        },
        {
          click: () => navigate(`/user/${globalContext.member.id}`),
          title: container.localeManager.strings.profile,
          icon: <FontAwesomeIcon icon="user"></FontAwesomeIcon>,
        },
        {
          divider: true,
        },
        {
          click: () => navigate("/my/applications"),
          title: container.localeManager.strings.myApplications,
          icon: <FontAwesomeIcon icon="user-plus"></FontAwesomeIcon>,
        },
        {
          click: () => navigate("/my/subscriptions"),
          title: container.localeManager.strings.mySubscriptions,
          icon: <FontAwesomeIcon icon="user-plus"></FontAwesomeIcon>,
        }
      );
    }

    /*
        if (globalContext.member && (container.dataService.data.enablePremiumFeature() || isTeam(globalContext.member))) {
            menu.push({
                click: () => navigate('/gamedashboard'),
                title: <>{container.localeManager.strings.mobileDashboard} <FontAwesomeIcon icon="star" className="text-premium ms-3"></FontAwesomeIcon></>,
                icon: <FontAwesomeIcon icon="dashboard"></FontAwesomeIcon>,
                className: 'text-premium'
            })
        }
        else {
            menu.push({
                click: () => navigate('/premium'),
                title: <>{container.localeManager.strings.mobileDashboard} <FontAwesomeIcon icon="star" className="text-premium ms-3"></FontAwesomeIcon></>,
                icon: <FontAwesomeIcon icon="dashboard"></FontAwesomeIcon>,
                className: 'text-premium'
            })
        }
        */

    menu.push({
      click: logout,
      title: container.localeManager.strings.logout,
      icon: <FontAwesomeIcon icon="sign-out"></FontAwesomeIcon>,
    });

    return menu;
  };

  const logout = () => {
    truckyService.removeToken();
    setGlobalContext({ user: null, company: null });
    container.dataService.data.patreonMember = null;
    container.realtimeService.disconnect();
    navigate("/");
  };

  const getLoginUrl = () => {
    return `${
      config.ENTERPRISE_URL
    }/authentication/login/steam?returnUrl=auth${encodeURIComponent(
      `?returnUrl=${window.location.pathname}`
    )}`;
  };

  const navigateToSearch = () => {
    if (searchTerm != "" && searchTerm.length >= 3)
      navigate(`/search?term=${searchTerm}`);
  };

  return (
    <div class="fixed-top">
      {!sessionStorage.getItem("inapp") && (
        <div className="d-none d-md-block app-header-top container-fluid">
          <div className="h-30 bg-secondary d-flex justify-content-between py-2 pe-5 w-100">
            <div className="ps-3">
              <a
                href="https://truckyapp.com/discord"
                target="_blank"
                className="me-5 text-white"
              >
                <FontAwesomeIcon icon={["fab", "discord"]}></FontAwesomeIcon>
                {container.localeManager.strings.joinDiscord}
              </a>
              <a
                href="https://truckyapp.com/overlay"
                target="_blank"
                className="text-white d-sm-none d-lg-inline-block"
              >
                <FontAwesomeIcon icon="download"></FontAwesomeIcon>
                {container.localeManager.strings.downloadTrucky}
              </a>
            </div>
            <div className="d-sm-none d-lg-inline-block">
              <Link className="text-white me-5" to="/servers">
                <FontAwesomeIcon icon="server"></FontAwesomeIcon>
                {container.localeManager.strings.routeServersTitle}
              </Link>
              <Link className="text-white me-5" to="/traffic">
                <FontAwesomeIcon icon="traffic-light"></FontAwesomeIcon>
                {container.localeManager.strings.traffic}
              </Link>
              <Link className="text-white me-5" to="/map">
                <FontAwesomeIcon icon="map"></FontAwesomeIcon>
                {container.localeManager.strings.map}
              </Link>
              <Link className="text-white" to="/hosting">
                <FontAwesomeIcon icon="chalkboard-user"></FontAwesomeIcon>
                {container.localeManager.strings.dedicatedServers}
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className="app-header shadow">
        <div className="app-container container-fluid d-flex align-items-stretch justify-content-between">
          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1 w-100">
            <div
              class="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2"
              title="Show sidebar menu"
            >
              <div
                class="btn btn-icon btn-active-color-primary mx-3"
                id="kt_drawer_example_basic_button"
              >
                <FontAwesomeIcon
                  icon="bars"
                  className="mx-0"
                  size="2x"
                ></FontAwesomeIcon>
              </div>
            </div>
            <div className="d-flex align-items-center ms-none ms-md-3">
              <Link to="/">
                <img
                  src="/assets/trucky_hub_red.png"
                  class="logo w-200px w-md-400px"
                />
              </Link>
            </div>

            <div
              class="d-none d-lg-flex menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
              id="#kt_aside_menu"
              data-kt-menu="true"
              data-kt-menu-expand="false"
            >
              <>
                <div className="menu-item">
                  <Link
                    to="/directory"
                    className={classnames({
                      "menu-link": true,
                      active: active == "/directory",
                    })}
                    title=""
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-dismiss="click"
                    data-bs-placement="right"
                  >
                    <span class="menu-icon">
                      <FontAwesomeIcon
                        icon="database"
                        className="mx-0"
                      ></FontAwesomeIcon>
                    </span>
                    <span class="menu-title">
                      {container.localeManager.strings.vtcDirectory}
                    </span>
                  </Link>
                </div>
                <div className="menu-item">
                  <Link
                    to="/leaderboards"
                    className={classnames({
                      "menu-link": true,
                      active: active == "/leaderboards",
                    })}
                    title=""
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-dismiss="click"
                    data-bs-placement="right"
                  >
                    <span class="menu-icon">
                      <FontAwesomeIcon
                        icon="users"
                        className="mx-0"
                      ></FontAwesomeIcon>
                    </span>
                    <span class="menu-title">
                      {container.localeManager.strings.leaderboards}
                    </span>
                  </Link>
                </div>
                {globalContext.company == null && (
                  <div className="menu-item">
                    <Link
                      to="/register-your-company"
                      className={classnames({
                        "menu-link": true,
                        active: active == "/register-your-company",
                      })}
                      title=""
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      data-bs-dismiss="click"
                      data-bs-placement="right"
                    >
                      <span class="menu-icon">
                        <FontAwesomeIcon
                          icon="rocket"
                          className="mx-0"
                        ></FontAwesomeIcon>
                      </span>
                      <span class="menu-title">
                        {container.localeManager.strings.registerYourCompany}
                      </span>
                    </Link>
                  </div>
                )}
                {globalContext.company != null && (
                  <div className="menu-item">
                    <div
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement="bottom-start"
                      class="menu-item py-2"
                      className={classnames({
                        "menu-link": true,
                        active: active == "/vtchub",
                      })}
                      title=""
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      data-bs-dismiss="click"
                      data-bs-placement="right"
                    >
                      <span class="menu-icon">
                        <FontAwesomeIcon
                          icon="building"
                          className="mx-0"
                        ></FontAwesomeIcon>
                      </span>
                      <Link to="/vtchub" className="menu-title">
                        {container.localeManager.strings.vtcHub}
                      </Link>
                      <span class="menu-arrow" className="text-white"></span>
                      <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-850px">
                        <div
                          class="menu-state-bg menu-extended overflow-hidden"
                          data-kt-menu-dismiss="true"
                        >
                          <div class="row">
                            <div class="col-lg-8 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6">
                              <div class="row">
                                <div class="col-lg-6 mb-3">
                                  <div class="menu-item p-0 m-0">
                                    <Link to="/vtchub" className="menu-link">
                                      <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <span class="svg-icon svg-icon-white svg-icon-1">
                                          <FontAwesomeIcon icon="home"></FontAwesomeIcon>
                                        </span>
                                      </span>
                                      <span class="d-flex flex-column">
                                        <span class="fs-6 fw-bold text-gray-800">
                                          {
                                            container.localeManager.strings
                                              .dashboard
                                          }
                                        </span>
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                                <div class="col-lg-6 mb-3">
                                  {es.can(
                                    globalContext.member,
                                    "company.*"
                                  ) && (
                                    <Link
                                      to="/company/settings"
                                      className="menu-link"
                                    >
                                      <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <span class="svg-icon svg-icon-primary svg-icon-1">
                                          <FontAwesomeIcon icon="gear"></FontAwesomeIcon>
                                        </span>
                                      </span>
                                      <span class="d-flex flex-column">
                                        <span class="fs-6 fw-bold text-gray-800">
                                          {
                                            container.localeManager.strings
                                              .companySettings
                                          }
                                        </span>
                                      </span>
                                    </Link>
                                  )}
                                </div>
                                <div class="col-lg-6 mb-3">
                                  <div class="menu-item p-0 m-0">
                                    <Link
                                      to="/company/members"
                                      className="menu-link"
                                    >
                                      <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <span class="svg-icon svg-icon-primary svg-icon-1">
                                          <FontAwesomeIcon icon="users"></FontAwesomeIcon>
                                        </span>
                                      </span>
                                      <span class="d-flex flex-column">
                                        <span class="fs-6 fw-bold text-gray-800">
                                          {
                                            container.localeManager.strings
                                              .members
                                          }
                                        </span>
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                                {es.can(
                                  globalContext.member,
                                  "members.manage_applications"
                                ) && (
                                  <div class="col-lg-6 mb-3">
                                    <div class="menu-item p-0 m-0">
                                      <Link
                                        to="/applications"
                                        className="menu-link"
                                      >
                                        <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                          <span class="svg-icon svg-icon-danger svg-icon-1">
                                            <FontAwesomeIcon icon="user-plus"></FontAwesomeIcon>
                                          </span>
                                        </span>
                                        <span class="d-flex flex-column">
                                          <span class="fs-6 fw-bold text-gray-800">
                                            {
                                              container.localeManager.strings
                                                .applications
                                            }
                                          </span>
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                )}
                                <div class="col-lg-6 mb-3">
                                  <div class="menu-item p-0 m-0">
                                    <Link to="/jobs" className="menu-link">
                                      <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <span class="svg-icon svg-icon-info svg-icon-1">
                                          <FontAwesomeIcon icon="suitcase"></FontAwesomeIcon>
                                        </span>
                                      </span>
                                      <span class="d-flex flex-column">
                                        <span class="fs-6 fw-bold text-gray-800">
                                          {container.localeManager.strings.jobs}
                                        </span>
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                                {globalContext.company != null &&
                                  (globalContext.company.company_type ==
                                    "realistic" ||
                                    globalContext.company.company_type ==
                                      "both") && (
                                    <>
                                      <div class="col-lg-6 mb-3">
                                        <div class="menu-item p-0 m-0">
                                          <Link
                                            to="/balance"
                                            className="menu-link"
                                          >
                                            <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                              <span class="svg-icon svg-icon-success svg-icon-1">
                                                <FontAwesomeIcon icon="piggy-bank"></FontAwesomeIcon>
                                              </span>
                                            </span>
                                            <span class="d-flex flex-column">
                                              <span class="fs-6 fw-bold text-gray-800">
                                                {
                                                  container.localeManager
                                                    .strings.balance
                                                }
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 mb-3">
                                        <div class="menu-item p-0 m-0">
                                          <Link
                                            to="/garages"
                                            className="menu-link"
                                          >
                                            <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                              <span class="svg-icon svg-icon-dark svg-icon-1">
                                                <FontAwesomeIcon icon="warehouse"></FontAwesomeIcon>
                                              </span>
                                            </span>
                                            <span class="d-flex flex-column">
                                              <span class="fs-6 fw-bold text-gray-800">
                                                {
                                                  container.localeManager
                                                    .strings.garages
                                                }
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 mb-3">
                                        <div class="menu-item p-0 m-0">
                                          <Link
                                            to="/vehicles"
                                            className="menu-link"
                                          >
                                            <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                              <span class="svg-icon svg-icon-warning svg-icon-1">
                                                <FontAwesomeIcon icon="truck"></FontAwesomeIcon>
                                              </span>
                                            </span>
                                            <span class="d-flex flex-column">
                                              <span class="fs-6 fw-bold text-gray-800">
                                                {
                                                  container.localeManager
                                                    .strings.fleet
                                                }
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 mb-3">
                                        <div class="menu-item p-0 m-0">
                                          <Link
                                            to="/maintenances"
                                            className="menu-link"
                                          >
                                            <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                              <span class="svg-icon svg-icon-primary svg-icon-1">
                                                <FontAwesomeIcon icon="wrench"></FontAwesomeIcon>
                                              </span>
                                            </span>
                                            <span class="d-flex flex-column">
                                              <span class="fs-6 fw-bold text-gray-800">
                                                {
                                                  container.localeManager
                                                    .strings.maintenance
                                                }
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                <div class="col-lg-6 mb-3">
                                  <div class="menu-item p-0 m-0">
                                    <Link to="/stats" className="menu-link">
                                      <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <span class="svg-icon svg-icon-danger svg-icon-1">
                                          <FontAwesomeIcon icon="table"></FontAwesomeIcon>
                                        </span>
                                      </span>
                                      <span class="d-flex flex-column">
                                        <span class="fs-6 fw-bold text-gray-800">
                                          {
                                            container.localeManager.strings
                                              .stats
                                          }
                                        </span>
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                                {es.can(
                                  globalContext.member,
                                  "feeds.manage"
                                ) && (
                                  <div class="col-lg-6 mb-3">
                                    <div class="menu-item p-0 m-0">
                                      <Link
                                        to="/feeds/manage"
                                        className="menu-link"
                                      >
                                        <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                          <span class="svg-icon svg-icon-info svg-icon-1">
                                            <FontAwesomeIcon icon="newspaper"></FontAwesomeIcon>
                                          </span>
                                        </span>
                                        <span class="d-flex flex-column">
                                          <span class="fs-6 fw-bold text-gray-800">
                                            {
                                              container.localeManager.strings
                                                .newsFeed
                                            }
                                          </span>
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                )}
                                {es.can(
                                  globalContext.member,
                                  "achievements.manage"
                                ) && (
                                  <div class="col-lg-6 mb-3">
                                    <div class="menu-item p-0 m-0">
                                      <Link
                                        to="/company/achievements"
                                        className="menu-link"
                                      >
                                        <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                          <span class="svg-icon svg-icon-warning svg-icon-1">
                                            <FontAwesomeIcon icon="trophy"></FontAwesomeIcon>
                                          </span>
                                        </span>
                                        <span class="d-flex flex-column">
                                          <span class="fs-6 fw-bold text-gray-800">
                                            {
                                              container.localeManager.strings
                                                .achievements
                                            }
                                          </span>
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="menu-more bg-light col-lg-4 py-3 px-3 py-lg-6 px-lg-6 rounded-end  d-flex flex-column">
                              {globalContext.company != null &&
                                (globalContext.company.company_type ==
                                  "realistic" ||
                                  globalContext.company.company_type ==
                                    "both") && (
                                  <div className="mb-3">
                                    <h4 class="fs-6 fs-lg-4 text-gray-800 fw-bold mt-3 mb-3 ms-4">
                                      {
                                        container.localeManager.strings
                                          .globalMarket
                                      }
                                    </h4>
                                    <div class="menu-item p-0 m-0">
                                      <Link
                                        to="/market/1"
                                        className="menu-link py-2"
                                      >
                                        <span class="menu-title">
                                          {
                                            container.localeManager.strings
                                              .europe
                                          }
                                        </span>
                                      </Link>
                                    </div>
                                    <div class="menu-item p-0 m-0">
                                      <Link
                                        to="/market/2"
                                        className="menu-link py-2"
                                      >
                                        <span class="menu-title">
                                          {container.localeManager.strings.usa}
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                )}
                              <div className="align-self-end text-center mt-auto">
                                <span className="fs-2">
                                  {globalContext.company.name}
                                </span>
                                <img
                                  src={globalContext.company.avatar_url}
                                  className="h-200px"
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
              {!container.dataService.data.enablePremiumFeature() && (
                <div className="menu-item">
                  <Link className="text-premium menu-link" to="/premium">
                    <span class="menu-icon">
                      <FontAwesomeIcon
                        icon="star"
                        className="mx-0"
                      ></FontAwesomeIcon>
                    </span>
                    <span class="menu-title">Go Premium!</span>
                  </Link>
                </div>
              )}
            </div>
            <div className="d-flex d-none d-lg-flex pe-5 align-items-center w-lg-550px">
              <InputGroup>
                <Input
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder={
                    container.localeManager.strings.searchForPlayerName
                  }
                  onKeyDown={(e) =>
                    e.key == "Enter" ? navigateToSearch() : true
                  }
                ></Input>
                <Button onClick={navigateToSearch}>
                  <FontAwesomeIcon
                    icon="search"
                    className="mx-0"
                  ></FontAwesomeIcon>
                </Button>
              </InputGroup>
            </div>
            <div className="d-flex d-md-flex pe-5 align-items-center">
              {truckyService.getToken() &&
                globalContext.member != undefined &&
                globalContext.member != null &&
                globalContext.member.company_id != null && (
                  <div class="d-flex align-items-center ms-3">
                    <Button
                      id="company_presence_drawer_button"
                      color="text"
                      className="btn-icon"
                    >
                      <FontAwesomeIcon icon="users"></FontAwesomeIcon>
                    </Button>
                    <UncontrolledTooltip target="company_presence_drawer_button">
                      {container.localeManager.strings.liveCompanyStatus}
                    </UncontrolledTooltip>
                  </div>
                )}
              {truckyService.getToken() &&
                globalContext.member != undefined &&
                globalContext.member != null &&
                globalContext.member.company_id != null && (
                  <div class="d-flex align-items-center ms-3">
                    <Button
                      id="messenger_button"
                      onClick={() => navigate("/messenger")}
                      color="text"
                      className="btn-icon"
                    >
                      <FontAwesomeIcon icon="message"></FontAwesomeIcon>
                      {unreadMessagesCount > 0 && (
                        <CustomBadge
                          size="sm"
                          color="success"
                          light={true}
                          className="ms-3"
                        >
                          {unreadMessagesCount}
                        </CustomBadge>
                      )}
                    </Button>
                    <UncontrolledTooltip target="messenger_button">
                      {container.localeManager.strings.messenger}
                    </UncontrolledTooltip>
                  </div>
                )}

              {globalContext.member != undefined &&
                globalContext.member != null && (
                  <div class="d-flex align-items-center ms-3">
                    <Button
                      id="notifications_button"
                      {...{
                        "data-kt-menu-trigger": "hover",
                        "data-kt-menu-attach": "parent",
                        "data-kt-menu-placement": "bottom-end",
                      }}
                      tag="a"
                      color="text"
                      className="btn-icon"
                    >
                      <FontAwesomeIcon icon="bell"></FontAwesomeIcon>
                      {notifications.length > 0 && (
                        <CustomBadge
                          className="ms-3"
                          color="success"
                          light={true}
                        >
                          {notifications.length}
                        </CustomBadge>
                      )}
                    </Button>
                    {notifications.length == 0 && (
                      <UncontrolledTooltip target="notifications_button">
                        {container.localeManager.strings.notifications}
                      </UncontrolledTooltip>
                    )}
                    {notifications.length > 0 && (
                      <div
                        class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
                        id="notifications-dropdown"
                        data-kt-menu="true"
                      >
                        <h3 class="text-white fw-bold px-9 mt-10 mb-6">
                          {container.localeManager.strings.notifications}{" "}
                          <span class="fs-8 opacity-75 ps-3">
                            {notifications.length}
                          </span>
                        </h3>
                        <div class="scroll-y mh-325px my-5 px-8">
                          {notifications.map((m) => {
                            return (
                              <div class="d-flex flex-stack py-4">
                                <div class="d-flex align-items-center me-2">
                                  <a
                                    onClick={() => readNotification(m)}
                                    class="text-gray-800 text-hover-primary fw-bold"
                                  >
                                    {m.data.text}
                                  </a>
                                </div>
                                <span class="badge badge-light fs-8">
                                  {container.localeManager
                                    .moment(m.created_at)
                                    .fromNow()}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                        <div class="py-3 text-center border-top">
                          <a
                            onClick={readAllNotifications}
                            class="btn btn-color-gray-600 btn-active-color-primary"
                          >
                            {container.localeManager.strings.readAll}
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              {truckyService.getToken() &&
                globalContext.member != undefined &&
                globalContext.member != null && (
                  <div class="d-flex align-items-center ms-5 ms-lg-5 d-none d-md-inline-block">
                    <ButtonDropdownMenu
                      buttonColor="text"
                      buttonClassNames="d-flex align-items-center"
                      buttonContent={
                        <>
                          <img
                            src={globalContext.member.avatar_url}
                            className="avatar h-25px rounded me-3"
                          />
                          {globalContext.member.name}
                        </>
                      }
                      menuTitle={
                        <>
                          <div className="d-flex align-items-center">
                            <img
                              src={globalContext.member.avatar_url}
                              className="avatar h-50px rounded me-3"
                            />
                            <div class="d-flex flex-column">
                              <div class="fw-bolder d-flex align-items-center fs-5">
                                {globalContext.member.name}
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      menuItems={getPersonalMenu()}
                      menuWidth={250}
                    ></ButtonDropdownMenu>
                  </div>
                )}
              {!truckyService.getToken() && (
                <div class="ms-5 ms-lg-5">
                  <Button
                    tag="a"
                    className="w-100 d-flex align-items-center"
                    href={getLoginUrl()}
                    color="secondary"
                  >
                    <FontAwesomeIcon
                      icon="user-lock"
                      className="mx-0 mx-md-3"
                    ></FontAwesomeIcon>
                    <span class="d-none d-md-inline-block">
                      {container.localeManager.strings.login}
                    </span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
